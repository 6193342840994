import { Col as ColGrid } from 'react-styled-flexboxgrid';

import React, { PropsWithChildren, PureComponent } from 'react';

import styled from 'styled-components';

import { getStyle } from '@shared/markup/functions';

type ColProps = {
  isMarked?: boolean;
  className?: string;
  mb?: number;
  md?: number;
  xs?: number | boolean;
  sm?: number | boolean;
  lg?: number;
  smOffset?: number;
  lgOffset?: number;
  onClick?: () => void;
};

class Col extends PureComponent<PropsWithChildren<ColProps>> {
  render() {
    return (
      <ColGrid role="cell" {...this.props}>
        {this.props.children}
      </ColGrid>
    );
  }
}

const StyledCol = styled(Col)<{ auto?: boolean }>`
  ${({ mb }) => getStyle(mb, 'margin-bottom', 'em')}
`;

export default StyledCol;
