import React, { PureComponent } from 'react';

import styled, { css } from 'styled-components';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import Circle from '../atoms/Circle/Circle';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Icon from '../atoms/Icon';
import Image from '../atoms/Image/Image';
import Link from '../atoms/Link/Link';
import Text from '../atoms/Text/Text';

import { ROUTE } from '@utils/index';
import analyticsManager from '@utils/analyticsManager';

import { Color } from '@shared/enums/button-color';
import { DEFAULT_AVATAR } from '@shared/markup/constants';
import { HaveAccountPopup } from '@hoc/HaveAccountPopup';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Menu } from '@shared/interfaces/menu-item';
import { Size } from '@shared/enums/button-size';
import { Target } from '@shared/enums/target';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const DefaultImage = styled(Image)`
  height: 1.5em;
  margin-bottom: -0.25em;
`;

const TextExtend = styled(Text)`
  font-size: 15px;
  white-space: nowrap;
  font-weight: 600;

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.turquoise};
    `};

  &:hover {
    color: ${props => props.theme.colors[props.hoverColor]};

    ${props =>
      props.isActive &&
      css`
        color: ${props => props.theme.colors.turquoise};
      `};
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin-right: 2em;
`;

const ItemAccount = styled(Item)`
  margin-right: 2em;
`;

const sharedLinkCss = css`
  padding: 0 0.075em 0;
  color: ${props => props.theme.colors.white};
`;

const ExternalLinkExtend = styled(ExternalLink)`
  ${sharedLinkCss};
`;

const LinkExtend = styled(Link)`
  ${sharedLinkCss};
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type NavbarProps = {
  className?: string;
  items: Menu[];
  isAuthorized?: boolean;
  avatar?: string;
  userName?: string;
  isActive: boolean;
  onClickTiff: () => void;
  t: I18nTranslation;
  showHaveAccountPopup?: (
    p: null,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
};

class Navbar extends PureComponent<NavbarProps> {
  t = key => this.props.t(`header.${key}`);

  handleAccountClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    this.props.showHaveAccountPopup(null, event);
  };

  renderAvatar = () => {
    const { avatar, isActive, userName } = this.props;

    const avatarEl = avatar ? (
      <Image src={avatar} height={2.125} />
    ) : (
      <DefaultImage src={DEFAULT_AVATAR} />
    );

    return (
      <ItemAccount>
        <LinkExtend href={ROUTE.ACCOUNT.INDEX}>
          <InnerWrapper>
            <Circle backgroundColor="white" size={1.75}>
              {avatarEl}
            </Circle>
            <Box ml={0.75}>
              <TextExtend isActive={isActive} weight={600}>
                {userName || this.t('myAccount')}
              </TextExtend>
            </Box>
          </InnerWrapper>
        </LinkExtend>
      </ItemAccount>
    );
  };

  renderAccountLink = () => {
    const color = this.props.isActive ? 'turquoise' : 'white';

    return (
      <ItemAccount>
        <LinkExtend
          href={ROUTE.ACCOUNT.INDEX}
          onClick={this.handleAccountClick}
        >
          <Icon name="account" color={color} />
        </LinkExtend>
      </ItemAccount>
    );
  };

  render() {
    const { className, items, isAuthorized } = this.props;

    items.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }

      if (a.order < b.order) {
        return -1;
      }

      return 0;
    });

    return (
      <ul className={className}>
        {items.map(item => {
          const Component =
            item.url[0] === '/' ? LinkExtend : ExternalLinkExtend;
          const target = item.externalLink ? Target.Blank : Target.Self;

          return (
            <Item key={item.id}>
              <Component
                href={item.url}
                target={target}
                onClick={() => {
                  analyticsManager.google.addItemView(item.id);
                }}
              >
                <TextExtend
                  isActive={item.isActive}
                  weight={600}
                  color={item.color}
                  hoverColor={item.hoverColor}
                >
                  {this.t(item.id)}
                </TextExtend>
              </Component>
            </Item>
          );
        })}
        {isAuthorized ? this.renderAvatar() : this.renderAccountLink()}
        {!isAuthorized && (
          <ItemAccount>
            <StyledButton
              onClick={this.props.onClickTiff}
              colorType={Color.FullGreen}
              sizeType={Size.Six}
            >
              {this.t('try')}
            </StyledButton>
          </ItemAccount>
        )}
      </ul>
    );
  }
}

const styledNavbar = styled(withTranslation('common')(Navbar))`
  display: none;

  ${breakpoint.med`
    display: flex;
  `}
`;

export default HaveAccountPopup(styledNavbar);
