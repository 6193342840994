import React, { Component, PropsWithChildren } from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { AccessTokenWarningState } from '@redux/reducers/accessTokenWarning/types';
import { AppState } from '@redux/types';
import { ProfileWithFullName } from '@redux/reducers/profile/types';
import { _getAccessTokenWarning } from '@redux/reducers/accessTokenWarning/selectors';
import { _getProfile } from '@redux/reducers/profile/selectors';
import { _isAuthorized } from '@redux/reducers/auth/selectors';
import { hideAccessTokenWarning } from '@redux/reducers/accessTokenWarning/action';
import { login } from '@redux/reducers/auth/action';

import HeaderCA from '../molecules/HeaderCA';

import { getUrl } from '@utils/origin';

import AccessTokenWarningPopup from './AccessTokenWarningPopup';
import { HaveAccountPopup } from '@hoc/HaveAccountPopup';
import Header from './Header';
import { HeaderType, TRIAL_SETUP_LINK } from '@shared/markup/constants';
import { NextRouter } from 'next/dist/client/router';
import { withRouter } from 'next/router';

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

const OuterWrapper = styled.div<{ isHeaderCA: boolean }>`
  height: 100vh;
  padding-top: ${({ isHeaderCA }) => (isHeaderCA ? 5 : 3.125)}em;
  overflow: hidden;
`;

const Content = styled.div`
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

interface LayoutProps extends NextRouter {
  profile: ProfileWithFullName;
  isAuthorized?: boolean;
  dispatch: (data: any) => Promise<void>;
  accessTokenWarning?: AccessTokenWarningState;
  headerType?: HeaderType;
  discount?: string;
  router: { pathname: string };
  showHaveAccountPopup?: (
    p: any,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

class Layout extends Component<PropsWithChildren<LayoutProps>> {
  handleLogin = e => {
    this.props.showHaveAccountPopup(() => {
      this.props.dispatch(login(false)).then(() => {
        window.location.href = getUrl(TRIAL_SETUP_LINK);
      });
    }, e);
  };

  onCloseAccessTokenWarning = () => {
    this.props.dispatch(hideAccessTokenWarning());
  };

  render() {
    const {
      discount,
      headerType,
      profile,
      children,
      accessTokenWarning,
      isAuthorized,
      router,
    } = this.props;
    const isHeaderCA = headerType === HeaderType.Ca;

    return (
      <OuterWrapper isHeaderCA={isHeaderCA}>
        {isHeaderCA ? (
          <HeaderCA discount={discount} />
        ) : (
          <Header
            pathname={router.pathname}
            avatar={profile.image}
            userName={profile.firstName}
            isAuthorized={isAuthorized}
            onLogin={this.handleLogin}
          />
        )}
        <Wrapper id="layout-wrapper">
          <Content id="layout-content">
            {children}
            <AccessTokenWarningPopup
              isOpen={accessTokenWarning.show}
              onClose={this.onCloseAccessTokenWarning}
            />
          </Content>
        </Wrapper>
      </OuterWrapper>
    );
  }
}

const withConnect = connect((state: AppState) => {
  return {
    isAuthorized: _isAuthorized(state),
    profile: _getProfile(state),
    accessTokenWarning: _getAccessTokenWarning(state),
  };
});

// @ts-ignore // TODO: fix error after the HaveAccountPopup HOC will be updated
export default HaveAccountPopup(withRouter(withConnect(Layout)));
