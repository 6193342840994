export enum Color {
  FullBlue = 'fullBlue',
  FullRed = 'fullRed',
  FullWhite = 'fullWhite',
  FullGrey = 'fullGrey',
  FullGreen = 'fullGreen',
  FullWhiteTurquoise = 'fullWhiteTurquoise',
  FullTransparentBorderLightGrey = 'fullTransparentBorderLightGrey',
  FullTransparentBorderGreen = 'fullTransparentBorderGreen',
  FullTransparentBorderWhite = 'fullTransparentBorderWhite',
  Grey = 'grey',
  GrayChateau = 'grayChateau',
  TransparentGrey = 'transparentGrey',
  FullYellow = 'fullYellow',
  FullMulberry = 'fullMulberry',
  FullWhiteWithBorder = 'fullWhiteWithBorder',
}
