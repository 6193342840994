import React, { Fragment, PureComponent } from 'react';

import styled, { css } from 'styled-components';

import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Image from '../atoms/Image/Image';
import MobileNavbar from '../molecules/MobileNavbar';
import Navbar from '../molecules/Navbar';

import { ORIGIN, isCN, isNonProd } from '@utils/origin';

import { ROUTE } from '@utils/index';

import { LANGUAGE } from '@shared/application/constants';
import { Menu } from '@shared/interfaces/menu-item';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

const tabletStyle = css`
  ${breakpoint.med`
    display: none;
  `}
`;

const Hamburger = styled.div`
  width: 1.375em;
  height: 1em;
  cursor: pointer;
  position: relative;

  ${tabletStyle};
`;

const Stick = styled.div<{ isOpenMainMenu: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.125em;
  height: 0.125em;
  width: 100%;
  transition: 0.25s ease-in-out;

  &:nth-child(2),
  &:nth-child(3) {
    top: 7px;
  }

  &:nth-child(4) {
    top: 14px;
  }

  ${props =>
    props.isOpenMainMenu &&
    `
    &:nth-child(1) {
      top: 7px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 7px;
      width: 0%;
      left: 50%;
    }
  `}
`;

const HeaderBox = styled.div`
  background-color: ${props => props.theme.colors.cornflowerBlue};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-bottom: 1px solid ${({ theme }) => theme.transparentColors.black15};
  display: flex;
  align-items: center;
  padding-left: 1.5em;
  padding-right: 1.5em;
  height: 50px;
`;

const MobileNavbarExtend = styled(MobileNavbar)`
  ${tabletStyle};
`;

const ExternalLinkExtend = styled(ExternalLink)`
  text-align: center;
  flex-grow: 1;

  ${breakpoint.med`
    text-align: left;
  `}
`;

type HeaderProps = {
  avatar?: string;
  userName?: string;
  isAuthorized?: boolean;
  onLogin: () => void;
  pathname: string;
  i18n: { language: string };
};

type HeaderState = {
  isOpenMainMenu: boolean;
};

class Header extends PureComponent<HeaderProps> {
  state: HeaderState = {
    isOpenMainMenu: false,
  };

  origin = `https://${isNonProd ? 'corpsite.nonprod.' : ''}${ORIGIN}`;

  getCurrentLang(lang) {
    switch (lang) {
      case LANGUAGE.Chinese:
        return 'cn.';
      case LANGUAGE.Spanish:
        return 'es.';
      case LANGUAGE.French:
        return 'fr.';
      case LANGUAGE.German:
        return 'de.';
      case LANGUAGE.English:
        return '';
    }
  }

  get menu(): Menu[] {
    return [
      {
        id: 'student',
        url: `${this.origin}/student`,
        isActive: false,
        externalLink: false,
        color: 'white',
        hoverColor: 'bombay',
        order: 1,
      },
      {
        id: 'professional',
        url: `${this.origin}/professional`,
        isActive: false,
        externalLink: false,
        color: 'white',
        hoverColor: 'bombay',
        order: 2,
      },
      {
        id: 'institutional',
        url: `${this.origin}/institutional`,
        isActive: false,
        externalLink: false,
        color: 'white',
        hoverColor: 'bombay',
        order: 3,
      },
      {
        id: 'pricing',
        url: ROUTE.PRICING,
        isActive: this.props.pathname !== ROUTE.ACCOUNT.INDEX,
        externalLink: false,
        color: 'white',
        hoverColor: 'bombay',
        order: 4,
      },
      {
        id: 'helpCenter',
        url: `https://${this.getCurrentLang(
          this.props.i18n.language
        )}service.elsevier.com/app/home/supporthub/complete-anatomy/`,
        isActive: false,
        externalLink: false,
        color: 'white',
        hoverColor: 'bombay',
        order: 5,
      },
    ];
  }

  handleMenu = () => {
    this.setState((state: HeaderState) => ({
      isOpenMainMenu: !state.isOpenMainMenu,
    }));
  };

  render() {
    const { pathname, isAuthorized, avatar, userName } = this.props;
    const isActive = pathname === ROUTE.ACCOUNT.INDEX;
    const logo = isCN
      ? 'https://cdn.completeanatomy.cn/website/branding/icons/company-logo.svg'
      : 'https://cdn.3d4medical.com/store/images/company-logo.svg';

    return (
      <Fragment>
        <HeaderBox>
          <Hamburger onClick={this.handleMenu}>
            <Stick isOpenMainMenu={this.state.isOpenMainMenu} />
            <Stick isOpenMainMenu={this.state.isOpenMainMenu} />
            <Stick isOpenMainMenu={this.state.isOpenMainMenu} />
            <Stick isOpenMainMenu={this.state.isOpenMainMenu} />
          </Hamburger>
          <ExternalLinkExtend href={this.origin} ariaLabel="Home page">
            <Image src={logo} />
          </ExternalLinkExtend>
          <Navbar
            items={this.menu}
            isAuthorized={isAuthorized}
            avatar={avatar}
            userName={userName}
            isActive={isActive}
            onClickTiff={this.props.onLogin}
          />
        </HeaderBox>
        <MobileNavbarExtend
          isOpen={this.state.isOpenMainMenu}
          items={this.menu}
          isAuthorized={isAuthorized}
          avatar={avatar}
          userName={userName}
          isActive={isActive}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(Header);
