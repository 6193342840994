import React, { PureComponent } from 'react';

import Transition from 'react-transition-group/Transition';

import styled, { css } from 'styled-components';

import Box from '../atoms/Box/Box';
import Circle from '../atoms/Circle/Circle';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Icon from '../atoms/Icon';
import Image from '../atoms/Image/Image';
import Link from '../atoms/Link/Link';
import Text from '../atoms/Text/Text';

import { ROUTE } from '@utils/index';
import analyticsManager from '@utils/analyticsManager';

import { DEFAULT_AVATAR } from '@shared/markup/constants';
import { HaveAccountPopup } from '@hoc/HaveAccountPopup';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Menu } from '@shared/interfaces/menu-item';
import { ShowHaveAccountPopup } from '@shared/interfaces/show-have-account-popup';
import { Target } from '@shared/enums/target';
import { withTranslation } from 'i18n';

const DefaultImage = styled(Image)`
  height: 1.5em;
  margin-bottom: -0.25em;
`;

const BoxMenu = styled(Box)`
  overflow-y: auto;
  position: fixed;
`;

const MenuItem = styled(Box)`
  border-bottom: solid 1px #2b2b2b;
`;

const sharedLinkCss = css`
  display: block;
`;

const LinkMenuItem = styled(Link)`
  ${sharedLinkCss};
`;

const ExternalLinkMenuItem = styled(ExternalLink)`
  ${sharedLinkCss};
`;

const BoxMenuInner = styled.div`
  transition: opacity 300ms ease;
  transition-delay: 300ms;
  opacity: 0;
`;

const AvatarMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
`;

const TextExtend = styled(Text)<{ isActive?: boolean }>`
  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.turquoise};
    `};

  &:hover {
    color: ${props => props.theme.colors[props.hoverColor]};
  }
`;

type MobileNavbarProps = {
  className?: string;
  items: Menu[];
  isOpen?: boolean;
  isAuthorized?: boolean;
  avatar?: string;
  userName?: string;
  isActive: boolean;
  hoverColor?: string;
  t: I18nTranslation;
  showHaveAccountPopup?: ShowHaveAccountPopup;
};

class MobileNavbar extends PureComponent<MobileNavbarProps> {
  ref: React.RefObject<HTMLDivElement> = React.createRef();

  t = key => this.props.t(`header.${key}`);

  handleEntered = elem => {
    this.ref.current.style.opacity = '1';
    elem.style.height = '100%';
  };

  handleExit = (elem?: HTMLDivElement): void => {
    elem.style.height = '0';
  };

  handleAccountClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    this.props.showHaveAccountPopup(null, event);
  };

  renderAvatar = () => {
    const avatar = this.props.avatar ? (
      <Image src={this.props.avatar} height={2.125} />
    ) : (
      <DefaultImage src={DEFAULT_AVATAR} />
    );

    return (
      <LinkMenuItem href={ROUTE.ACCOUNT.INDEX}>
        <AvatarMenuItem pt={1.25} pb={1.25}>
          <Circle backgroundColor="white" size={1.75}>
            {avatar}
          </Circle>
          <Box ml={0.75}>
            <Text
              color={this.props.isActive ? 'turquoise' : 'white'}
              weight={600}
              lineHeight={1.1}
              size={1.375}
            >
              {this.props.userName || this.t('myAccount')}
            </Text>
          </Box>
        </AvatarMenuItem>
      </LinkMenuItem>
    );
  };

  renderAccountLink = () => {
    const color = this.props.isActive ? 'turquoise' : 'white';

    return (
      <LinkMenuItem
        href={ROUTE.ACCOUNT.INDEX}
        onClick={this.handleAccountClick}
      >
        <MenuItem pt={1.25} pb={1.25}>
          <Icon name="account" color={color} />
        </MenuItem>
      </LinkMenuItem>
    );
  };

  render() {
    const { isOpen, items, className, isAuthorized } = this.props;

    return (
      <Transition
        className={className}
        in={isOpen}
        timeout={{ enter: 0, exit: 300 }}
        onEntered={this.handleEntered}
        onExit={this.handleExit}
        unmountOnExit
        mountOnEnter
        appear
      >
        <BoxMenu backgroundColor="cornflowerBlue">
          <Box pl={1.25} pr={1.25} pb={5}>
            <BoxMenuInner ref={this.ref}>
              {items.map(item => {
                const Component =
                  item.url[0] === '/' ? LinkMenuItem : ExternalLinkMenuItem;
                const target = item.externalLink ? Target.Blank : Target.Self;

                return (
                  <Component
                    key={item.id}
                    href={item.url}
                    target={target}
                    onClick={() => {
                      analyticsManager.google.addItemView(item.id);
                    }}
                  >
                    <MenuItem pt={1.25} pb={1.25}>
                      <TextExtend
                        isActive={item.isActive}
                        weight={600}
                        lineHeight={1.1}
                        size={1.375}
                        color={item.color}
                        hoverColor={item.hoverColor}
                      >
                        {this.t(item.id)}
                      </TextExtend>
                    </MenuItem>
                  </Component>
                );
              })}
              {isAuthorized ? this.renderAvatar() : this.renderAccountLink()}
            </BoxMenuInner>
          </Box>
        </BoxMenu>
      </Transition>
    );
  }
}

const styledMobileNavbar = styled(withTranslation('common')(MobileNavbar))`
  position: fixed;
  top: 3.125em;
  left: 0;
  right: 0;
  z-index: 11;
  transition: height 300ms ease;
  height: 0;
`;

export default HaveAccountPopup(styledMobileNavbar);
