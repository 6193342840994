import React, { PropsWithChildren, PureComponent } from 'react';

import styled, { css } from 'styled-components';

import AliPayIcon from '@public/icons/alipay-logo.svg';
import ArrowIcon from '@public/icons/arrow.svg';
import { Color } from '@shared/enums/button-color';
import LockIcon from '@public/icons/lock.svg';
import PaypalIcon from '@public/icons/paypal.svg';
import { Size } from '@shared/enums/button-size';
import { breakpoint } from 'theme';
import { darken } from 'polished';

const one = css`
  width: 100%;
  font-size: 1.125em;
  border-radius: 0.25em;
  font-weight: 600;
  padding-top: 18px;
  padding-bottom: 18px;
  line-height: 1;

  ${breakpoint.m`
    font-size: 1.375em;
    padding-top: 16px;
    padding-bottom: 16px;
  `}
`;

const two = css`
  ${one};

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 1;
`;

const three = css`
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0.25em;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1;
`;

const fourth = css`
  ${three};

  font-size: 1.125em;

  ${breakpoint.m`
    font-size: 1.375em;
  `}
`;

const fourthMd = css`
  ${fourth};

  font-size: 1.125em;
  padding: 0 25px;
  width: auto;
  height: 60px;

  ${breakpoint.md`
    font-size: 1.375em;
  `}
`;

const fifth = css`
  font-size: 1em;
  text-transform: uppercase;
  height: 3.125em;
  width: 12.5em;
`;

const fifthFullWidth = css`
  ${fifth};
  width: 100%;
`;

const six = css`
  font-size: 15px;
  padding-left: 1em;
  padding-right: 1em;
  line-height: 38px;
  font-weight: 600;
`;

const seven = css`
  font-size: 1.125em;
  font-weight: bold;
  width: 100%;
  height: 3.125em;

  ${breakpoint.m`
    max-width: 12.625em;
  `}
`;

const eight = css`
  font-size: 1.125rem;
  font-weight: bold;
  width: 100%;
  height: 3.75rem;
  border-radius: 0.25rem;

  ${breakpoint.m`
    font-size: 1.375rem;
    max-width: 25.125rem;
  `}
`;

const ninth = css`
  ${three};

  font-size: 1.125em;
  font-weight: 600;

  ${breakpoint.m`
    max-width: 17.25rem;
  `}
`;

const ten = css`
  width: 100%;
  font-size: 1.125em;
  border-radius: 0.25em;
  font-weight: 600;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 1;

  ${breakpoint.m`
    font-size: 1.375em;
    padding-top: 5px;
    padding-bottom: 5px;
  `}
`;

const oneMaxWidth = css`
  ${one};

  max-width: 408px;
`;

const big = css`
  border-radius: 0.25em;
  width: 17em;
  height: 2.625em;
  font-weight: 600;
`;

const small = css`
  border-radius: 0.25em;
  padding: 0 1.125em;
  height: 1.75em;
  color: ${props => props.theme.colors.white};
  width: 100%;
`;

const lessMedium = css`
  border-radius: 0.25em;
  padding: 0 0.8em;
  height: 1.75em;
  color: ${props => props.theme.colors.white};
  width: 100%;

  ${breakpoint.lg`
    max-width: 10.25em;
  `}
`;

const medium = css`
  padding: 0.625em;
  border-radius: 0.25em;
  width: 100%;
  max-width: 11.25em;
`;

const mediumSquare = css`
  padding: 0.625em;
  border-radius: 0.25em;
  font-weight: 600;
`;

const huge = css`
  max-width: 21.375em;
  width: 100%;
  border-radius: 0.25em;
  font-size: 1em;
  height: 3.5em;
  font-weight: 600;

  ${breakpoint.lg`
    max-width: 23.25em;
  `}
`;

const fullWidth = css`
  width: 100%;
  height: 3.5em;
  font-weight: 600;
`;

const fullWidthDefault = css`
  width: 100%;
`;

const fullWhite = css`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.cerulean};
`;

const fullWhiteWithBorder = css`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.cerulean};
  box-shadow: 0 1px 4px 0 rgba(223, 226, 228, 0.25);
  border: solid 1px #dfe2e4;
`;

const fullBlue = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.white};
  transition-property: color, background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;

  ${props =>
    !props.disabled &&
    css`
      background-color: ${props => props.theme.colors.cerulean};
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${props => props.theme.colors.iron};
      pointer-events: none;
    `}
`;

const grey = css`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.iron};
`;

const transparentGrey = css`
  background-color: transparent;
  color: ${props => props.theme.colors.scarpaFlow};
  border: 1px solid ${props => props.theme.colors.scarpaFlow};
`;

const fullTransparentBorderGreen = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.turquoise};
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.turquoise};
  border-radius: 0.25em;

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.white};
      border-color: ${props.theme.colors.white};
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const fullTransparentBorderLightGrey = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.turquoise};
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.nebula};
  border-radius: 0.25em;

  ${props =>
    props.disabled &&
    css`
      background-color: ${props => props.theme.colors.iron};
      pointer-events: none;
    `}
`;

const fullTransparentBorderWhite = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.white};
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.white};
  border-radius: 0.25em;

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.white};
      border-color: ${props.theme.colors.white};
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const fullRed = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.white};
  transition-property: color, background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;

  ${props =>
    !props.disabled &&
    css`
      background-color: ${props => props.theme.colors.scarlet};
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${props => props.theme.colors.iron};
      pointer-events: none;
    `}
`;

const fullGrey = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.middleGrey};
  background-color: ${props => props.theme.colors.concrete};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const fullGreen = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.turquoise};
  border: 1px solid ${props => props.theme.colors.turquoise};
  border-radius: 0.25em;
  opacity: 1;
  transition: 0.25s background-color, 0.25s border-color, 0.25s opacity;

  &:hover {
    background-color: ${props => props.theme.colors.keppel};
    border-color: ${props => props.theme.colors.keppel};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

const fullMulberry = css`
  ${fullGreen};
  background-color: ${props => props.theme.colors.mulberry};
  border: 1px solid ${props => props.theme.colors.mulberry};

  &:hover {
    background-color: ${props => darken(0.13, props.theme.colors.mulberry)};
    border-color: ${props => darken(0.13, props.theme.colors.mulberry)};
  }
`;

const fullYellow = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.midnightBlue};
  background-color: ${props => props.theme.colors.sunglow};
  border-radius: 0.25em;
  transition: 0.25s background-color, opacity 0.25s;

  &:hover {
    background-color: ${props => props.theme.colors.saffron};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

const fullWhiteTurquoise = css`
  color: ${props => props.theme.colors.turquoise};
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.25em;
`;

const ctaHero = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.white};
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.turquoise};
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  width: 18.125rem;
  transition: 0.25s background-color, 0.25s border-color, 0.25s opacity;

  ${breakpoint.m`
    width: 16.125rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  `}

  &:hover {
    background-color: ${props => props.theme.colors.keppel};
    border-color: ${props => props.theme.colors.keppel};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

const tiffHero = css<{ disabled: boolean }>`
  color: ${props => props.theme.colors.white};
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.turquoise};
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  width: 18.125rem;
  transition: 0.25s background-color, 0.25s border-color, 0.25s opacity;

  ${breakpoint.m`
    width: 18rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 1.375rem;
  `}

  ${breakpoint.lg`
    font-size: 1.75rem;
  `}

  &:hover {
    background-color: ${props => props.theme.colors.keppel};
    border-color: ${props => props.theme.colors.keppel};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

type ButtonProps = {
  className?: string;
  size?: number;
  component?: 'a' | 'button';
  disabled?: boolean;
  sizeType?: Size;
  colorType?: Color;
  arrowType?: 'one' | 'lock' | 'paypal' | 'alipay';
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  ariaLabel?: string;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  tabIndex?: number;
  target?: string;
  variant?: 'ctaHero' | 'tiffHero';
};

class Button extends PureComponent<PropsWithChildren<ButtonProps>> {
  static defaultProps = {
    component: 'button',
    ariaLabel: '',
    type: 'button',
  };

  render() {
    const {
      component: Component,
      children,
      disabled,
      ariaLabel,
      href,
      className,
      id,
      onClick,
      target,
      type,
      tabIndex,
      arrowType,
    } = this.props;

    return (
      <Component
        role="button"
        id={id}
        className={className}
        type={Component === 'button' ? type : undefined}
        href={Component === 'a' ? href : undefined}
        disabled={Component === 'button' ? disabled : undefined}
        aria-label={Component === 'button' ? ariaLabel : undefined}
        onClick={onClick}
        target={Component === 'a' ? target : undefined}
        tabIndex={tabIndex}
      >
        {children}

        {arrowType === 'one' && (
          <ArrowIcon role="img" style={{ marginLeft: '0.5em' }} />
        )}

        {arrowType === 'lock' && (
          <LockIcon role="img" style={{ marginLeft: '0.5em' }} />
        )}

        {arrowType === 'paypal' && (
          <PaypalIcon
            width={100}
            height={24}
            role="img"
            style={{ marginLeft: '0.5em' }}
          />
        )}

        {arrowType === 'alipay' && (
          <AliPayIcon
            width={120}
            height={42}
            role="img"
            style={{ marginLeft: '0.5em' }}
          />
        )}
      </Component>
    );
  }
}

const styledButton = styled(Button)<ButtonProps>`
  font-size: 1em;
  ${props => props.size && `font-size: ${props.size}em;`};
  margin: 0;
  padding: 0;
  border-radius: 0;
  ${props =>
    props.component === 'a' &&
    css`
      display: inline-flex;
      justify-content: center;
      align-items: center;
    `};

  user-select: none;
  text-decoration: none;

  background: transparent;
  border: none;

  :focus {
    outline: none;
  }

  ${props =>
    !props.disabled &&
    `
      cursor: pointer;
  `};

  ${props => props.sizeType === 'big' && big}
  ${props => props.sizeType === 'medium' && medium}
  ${props => props.sizeType === 'lessMedium' && lessMedium}
  ${props => props.sizeType === 'small' && small}
  ${props => props.sizeType === 'fullWidth' && fullWidth}
  ${props => props.sizeType === 'fullWidthDefault' && fullWidthDefault}
  ${props => props.sizeType === 'huge' && huge}
  ${props => props.sizeType === 'mediumSquare' && mediumSquare}
  ${props => props.sizeType === 'one' && one}
  ${props => props.sizeType === 'oneMaxWidth' && oneMaxWidth}
  ${props => props.sizeType === 'two' && two}
  ${props => props.sizeType === 'three' && three}
  ${props => props.sizeType === 'fourth' && fourth}
  ${props => props.sizeType === 'fourthMd' && fourthMd}
  ${props => props.sizeType === 'fifth' && fifth}
  ${props => props.sizeType === 'fifthFullWidth' && fifthFullWidth}
  ${props => props.sizeType === 'six' && six}
  ${props => props.sizeType === 'seven' && seven}
  ${props => props.sizeType === 'eight' && eight}
  ${props => props.sizeType === 'ninth' && ninth}
  ${props => props.sizeType === 'ten' && ten}

  ${props => props.colorType === 'grey' && grey}
  ${props => props.colorType === 'transparentGrey' && transparentGrey}
  ${props => props.colorType === 'fullBlue' && fullBlue}
  ${props => props.colorType === 'fullRed' && fullRed}
  ${props => props.colorType === 'fullWhite' && fullWhite}
  ${props => props.colorType === 'fullGrey' && fullGrey}
  ${props => props.colorType === 'fullGreen' && fullGreen}
  ${props => props.colorType === 'fullMulberry' && fullMulberry}
  ${props => props.colorType === 'fullYellow' && fullYellow}
  ${props => props.colorType === 'fullWhiteTurquoise' && fullWhiteTurquoise}
  ${props =>
    props.colorType === 'fullTransparentBorderLightGrey' &&
    fullTransparentBorderLightGrey}
  ${props =>
    props.colorType === 'fullTransparentBorderGreen' &&
    fullTransparentBorderGreen}
  ${props =>
    props.colorType === 'fullTransparentBorderWhite' &&
    fullTransparentBorderWhite}
  ${props => props.colorType === 'fullWhiteWithBorder' && fullWhiteWithBorder}

  ${props => props.variant === 'ctaHero' && ctaHero}
  ${props => props.variant === 'tiffHero' && tiffHero}
`;

export default styledButton;
